import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link, useStaticQuery, graphql } from 'gatsby';
import breakpoints from '../utils/breakpoints';

const LogoWrap = styled.div`
  margin: auto 0;
  flex: 0 1 50px;

  @media (min-width: ${breakpoints.md}) {
    margin: auto 0;
    flex: 0 1 200px;
  }

  @media (max-width: 768px) and (orientation: landscape) {
    flex: 0 1 70px;
    padding-left: 0.5rem;
  }
`;

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "ss-logo" }, extension: { eq: "png" }) {
        childImageSharp {
          fixed(width: 170, height: 45, pngQuality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <LogoWrap as={Link} to="/">
      <Img fixed={data.file.childImageSharp.fixed} alt="logo" />
    </LogoWrap>
  );
};

export default Logo;
