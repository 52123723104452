/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, lang, meta, title, headTagData }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: 'robots',
          content: headTagData.robots
        },
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `keywords`,
          content: `Martial arts, brazilian jiu jitsu, bjj, gracie jiu jitsu, kickboxing, mixed martial arts, mma`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    >
      <script
        type="text/javascript"
        key="zenplanner"
      >{`(function (win, doc, zenJSHost, src, module, partitionApiKey, widgetInstanceId) { win.zenplanner = win.zenplanner || {}; win.zenplanner.directLoadArgs = win.zenplanner.directLoadArgs || []; var tryCount = 0, intervalId = null; function afterLoad() { if (win.zenplanner.directLoader !== undefined && window.zenplanner.directLoader !== null) { clearInterval(intervalId); for (var i = 0, l = win.zenplanner.directLoadArgs.length; l > i; i++) { if (win.zenplanner.directLoadArgs[i].widgetInstanceId === widgetInstanceId) { win.zenplanner.directLoader.loadWidget(zenJSHost, module, partitionApiKey, widgetInstanceId); } } } else if (tryCount++ > 200) { console.log('Zen Planner widget : ' + module + ', failed to load.'); clearInterval(intervalId); } } if (win.zenplanner.directLoader === undefined || win.zenplanner.directLoader === null) { win.zenplanner.directLoadArgs.push({ module: module, partitionApiKey: partitionApiKey, widgetInstanceId: widgetInstanceId }); var s = doc.createElement('script'); s.async = 1; s.src = zenJSHost + '/' + src; doc.head.appendChild(s); intervalId = setInterval(afterLoad, 50); } else { win.zenplanner.directLoader.loadWidget(zenJSHost, module, partitionApiKey, widgetInstanceId); } })(window, document, 'https://studio.zenplanner.com', 'zenplanner/studio/target/zp-widget-direct.js', 'leadcapture', 'd2ad4335-aa43-49ee-9ace-94e598bf1fe2', '31ecb54d-eee6-4220-8498-dfdfd3af3383');`}</script>
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  headTagData: {
    robots: 'index,follow'
  }
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default Seo;
