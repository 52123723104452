import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import styles from '../utils/styles';

export const GlobalStyles = createGlobalStyle`
  ${normalize};

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: ${styles.fontWeight.light};
    line-height: 1.5;
    color: ${styles.colors.gray[700]};
    font-size: ${styles.fontSize.lg};
  }

  h1, h2, h3, h4, h5, h6 {
  color: ${styles.colors.gray[800]};
  font-weight: 500;
  line-height: 1.15;
  margin: 0 0 .25em;
  }

  a {
    text-decoration: none;
    color: ${styles.colors.red[500]};
    font-weight: ${styles.fontWeight.bold};
  }

  .hidden{
    display:none;
  }
`;

export default GlobalStyles;
